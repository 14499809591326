import "@fontsource/noto-sans-jp"

import "./src/styles/foundation/variable.css"
import "./src/styles/foundation/normalize.css"
import "./src/styles/foundation/base.css"
import "./src/styles/foundation/blog-contents-style.css"

export const onRouteUpdate = () => {
  // ページ遷移時に Google Translate の再初期化を行う
  if (window.google && window.google.translate && window.google.translate.TranslateElement) {
    new window.google.translate.TranslateElement({pageLanguage: 'ja'}, 'google_translate_element');
  } else {
    const script = document.createElement("script");
    script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    script.type = "text/javascript";
    script.async = true;
    document.body.appendChild(script);
  }
};